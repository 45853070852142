<template>
    <div>
      <b-modal
        id="modal-edit-bed"
        size="lg"
        centered
        title="Edit Data Master Kamar"
        header-bg-variant="warning"
        header-text-variant="light"
      >
        <b-form>
            <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Bed <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_bed')"
              type="text"
              v-model="$v.data.nama_bed.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Ruang <span class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="$v.data.ms_ruang_id.$model"
                :state="checkIfValid('ms_ruang_id')"
                :options="option_ruang"
                @change="get_kamar(data.ms_ruang_id)"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Kamar <span class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="$v.data.ms_kamar_id.$model"
                :state="checkIfValid('ms_kamar_id')"
                :options="option_kamar"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Status Bed <span class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="$v.data.status_bed.$model"
                :state="checkIfValid('status_bed')"
                :options="option_status"
            ></b-form-select>
          </b-form-group>
        </b-form>
  
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit-bed')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
          >
            {{ button }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </template>
  <script>
  import _ from "lodash";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  export default {
    name: "modalEdit",
    props: ["data", 'option_ruang'],
    data() {
      return {
        busy: false,
        button: "Simpan",
        option_status: [
            {value:1, text:'Aktif'},
            {value:2, text:'Tidak Aktif'},
        ],
        option_kamar: []
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        nama_bed: {
          required,
        },
        ms_kamar_id: {
          required,
        },
        ms_ruang_id: {
          required,
        },
        status_bed: {
          required,
        },
      },
    },
    watch: {
      data(newVal) {
        let id = newVal.ms_ruang_id
        this.get_kamar(id)
      }
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async get_kamar(x){
        let vm = this
        let kamar = await this.$axios.post("/ms_kamar/list", {
          ms_ruang_id: x
        });
        console.log(kamar, 'ini kamar');
        vm.option_kamar = kamar.data.status == 200 ? kamar.data.data.map(item => { return {text: item.nama_kamar, value: item.ms_kamar_id} }) : []
      },
      simpan() {
        let vm = this;
        if (vm.isValid) {
          vm.busy = true;
          vm.button = "Mohon Tunggu";
          this.$axios.post("/ms_bed/update",{...vm.data, id: vm.data.ms_bed_id})
            .then((res) => {
              if (res.data.message == "sukses") {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "success",
                  msg: "BERHASIL MENGUBAH DAFTAR MASTER BED",
                  showing: true,
                });
                this.$bvModal.hide("modal-edit-bed");
                this.data.type = "";
              } else {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "danger",
                  msg: _.toUpper(res.data.message),
                  showing: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: "TERJADI KESALAHAN PADA SERVER",
                showing: true,
              });
            });
        }
      },
    },
  };
  </script>
  