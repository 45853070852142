<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Master Bed</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input-bed
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit-bed
                      @click="data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-bed
                      @click="data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input 
      :option_ruang="option_ruang"
      @alertFromChild="triggerAlert($event), getDatas()" 
    />
    <modal-edit
      :data="data"
      :option_kamar="option_kamar"
      :option_ruang="option_ruang"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-delete
      :data="data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
  import ModalDelete from "./modal_delete.vue";
  import ModalInput from "./modal_input.vue";
  import ModalEdit from "./modal_edit.vue";
  
  export default {
    components: {
      ModalInput,
      ModalDelete,
      ModalEdit,
    },
    name: "master_bed",
    data() {
      return {
        showing: false,
        variant: "success",
        option_kamar: [],
        option_ruang: [],
        msg: "",
        data: "",
        fields: [
          {
            key: "no",
            label: "No",
            sortDirection: "desc",
            sortable: true,
            class: "table-number text-center",
          },
          {
            key: "nama_bed",
            label: "Nama Bed",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "nama_kamar",
            label: "Nama Kamar",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "nama_ruang",
            label: "Nama Ruang",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "status",
            label: "Status Bed",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        tableBusy: false,
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.getDatas();
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getDatas() {
        this.tableBusy = true;
        let bed = await this.$axios.post("/ms_bed/list");
  
        this.items = bed.data.data;
        for (let i = 0; i < this.items.length; i++) {
          let x = this.items[i];
          x.no = i + 1;
          if(x.status_bed == 1){
            x.status = "Aktif"
          }else {
            x.status = "Tidak Aktif"
          }
        }
        this.totalRows = this.items.length;
        this.tableBusy = false;

        let kamar = await this.$axios.post("/ms_kamar/list");
        // console.log(kamar, 'ini kamar');
        this.option_kamar = kamar.data.status == 200 ? kamar.data.data.map(item => { return {text: item.nama_kamar, value: item.ms_kamar_id} }) : []

        let ruang = await this.$axios.post("/ms_ruang/list");
        // console.log(ruang, 'ini ruang');
        this.option_ruang = ruang.data.status == 200 ? ruang.data.data.map(item => { return {text: item.nama_ruang, value: item.ms_ruang_id} }) : []
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>
  