<template>
    <div>
      <b-modal
        id="modal-input-bed"
        size="lg"
        centered
        title="Tambah Data Master Bed"
        header-bg-variant="primary"
        header-text-variant="light"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Bed <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_bed')"
              type="text"
              v-model="$v.data.nama_bed.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Ruang <span class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="$v.data.ms_ruang_id.$model"
                :state="checkIfValid('ms_ruang_id')"
                :options="option_ruang"
                @change="get_kamar(data.ms_ruang_id)"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Kamar <span class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="$v.data.ms_kamar_id.$model"
                :state="checkIfValid('ms_kamar_id')"
                :options="option_kamar"
            ></b-form-select>
          </b-form-group>
          
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Status Bed <span class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="$v.data.status_bed.$model"
                :state="checkIfValid('status_bed')"
                :options="option_status"
            ></b-form-select>
          </b-form-group>
        </b-form>
        <!-- <img :src="src1" /> -->
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-bed')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "modalInput",
    props: ['option_ruang'],
    data() {
      return {
        data: {
          nama_bed: "",
          ms_kamar_id: "",
          ms_ruang_id: "",
          status_bed: "",
        },
        option_status: [
            {value:1, text:'Aktif'},
            {value:2, text:'Tidak Aktif'},
        ],
        option_kamar: [],
        src1: "",
        selected: null,
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        nama_bed: {
          required,
        },
        ms_kamar_id: {
          required,
        },
        ms_ruang_id: {
          required,
        },
        status_bed: {
          required,
        },
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async get_kamar(x){
        let vm = this
        let kamar = await this.$axios.post("/ms_kamar/list", {
          ms_ruang_id: x
        });
        // console.log(kamar, 'ini kamar');
        vm.option_kamar = kamar.data.status == 200 ? kamar.data.data.map(item => { return {text: item.nama_kamar, value: item.ms_kamar_id} }) : []
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        this.$axios.post("/ms_bed/register", vm.data)
          .then((res) => {
            console.log(res, "ini ress");
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER BED",
                showing: true,
              });
              this.$bvModal.hide("modal-input-bed");
              this.reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err)
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      reset() {
        this.$v.$reset();
        this.data = {
          nama_bed: "",
        };
      },
    },
  };
  </script>
  